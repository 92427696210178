import React, {FC, memo, useCallback, useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Form, Formik} from 'formik';
import zxcvbn from 'zxcvbn';
import classNames from 'classnames';
import Button from "../../../../shared/components/Button/Button";
import Input from "../../../../shared/components/Input/Input";
import InputField from "../../../../shared/components/InputField/InputField";
import PasswordStrengthMeter from "../../../../shared/components/PasswordStrengthMeter/PasswordStrengthMeter";

import {registerUser} from 'redux/features/user';
import {RootState} from 'redux/store';
import {setErrorMessage} from 'pages/RegisterUser/redux';
import cookie from 'js-cookie';
import size from 'lodash/size';
import styles from './RegisterForm.module.scss';
import config from 'config/env-config';

// interface Props {
//     inviteToken: string;
//     email: string;
// }
interface RegisterFormProps {
    currentWizardStep: number;
    onWizardChange: (value: number) => void;
}

const RegisterFormComponent: React.FC<RegisterFormProps> = ({currentWizardStep, onWizardChange}) => {
    const initialFormValues = {
        information: {
            first_name: '',
            last_name: '',
        },
        password: '',
        password_again: '',
    };

    const [termsAccepted, setTermsAccepted] = useState(false);
    const [passwordToValidate, setpasswordToValidate] = useState('');
    const [confirmPassword, setconfirmPassword] = useState('');
    const [showPass, setShowPass] = useState(!!cookie.get('provider') && cookie.get('provider') === 'xelacore' || true);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [isNextStepDisabled, setIsNextStepDisabled] = useState(true);

    const dispatch = useDispatch();
    const errorMessage = useSelector<RootState, string | undefined>((state) => state.registerUserPage.errorMessage);


    useEffect(() => {
        if (firstName.trim() === '' || lastName.trim() === '') {
            setIsNextStepDisabled(true);
        } else {
            setIsNextStepDisabled(false);
        }
        onWizardChange(currentWizardStep)
    }, [firstName, lastName, onWizardChange, currentWizardStep]);


    const onFormValueSubmit = useCallback((formValue) => {
        if (!size(formValue.information.first_name) || !size(formValue.information.last_name)) {
            dispatch(setErrorMessage('First and last name are mandatory fields'));
            return;
        }

        const registerUserEntity = {...formValue};
        delete registerUserEntity.password_again;

        if (!showPass) {
            delete registerUserEntity.password;
        }

        dispatch(registerUser({registerUserEntity}));
    }, []);

    const checkTermsAccepted = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTermsAccepted(e.target.checked)
    }

    const handleFormChange = (event: any) => {
        const {name, value} = event.target;

        if (name === 'information.first_name') {
            setFirstName(value ?? '')
        }
        if (name === 'information.last_name') {
            setLastName(value ?? '')
        }
        if (name === 'password') {
            setpasswordToValidate(value);
        }
        if (name === 'password_again') {
            setconfirmPassword(value);
        }
    }

    return (
        <Formik enableReinitialize
                initialValues={initialFormValues}
                onSubmit={(values) => onFormValueSubmit(values)}>
            {() => (
                <Form className={styles.root} onChange={(e) => handleFormChange(e)}>
                    {currentWizardStep === 1 && (
                        <div>
                            <InputField fieldName="First Name" newStyle={false}>
                                <Input name="information.first_name"></Input>
                            </InputField>
                            <InputField fieldName="Last Name" newStyle={false}>
                                <Input type="text" name="information.last_name"></Input>
                            </InputField>
                            {showPass && (
                                <>
                                    <InputField fieldName="Password" newStyle={false}>
                                        <>
                                            <Input type="password" name="password"></Input>
                                            <PasswordStrengthMeter
                                                password={passwordToValidate}></PasswordStrengthMeter>
                                        </>
                                    </InputField>
                                    <InputField fieldName="Repeat Password" newStyle={false}>
                                        <Input type="password" name="password_again"></Input>
                                    </InputField>

                                    {passwordToValidate && confirmPassword && passwordToValidate != confirmPassword && (

                                        <p className={classNames(styles.errorText)}>
                                            Passwords do not match
                                        </p>

                                    )}
                                </>
                            )}
                        </div>)}
                    {currentWizardStep === 2 && (
                        <div>
                            <div className={styles.termsTextWrapper}>
                                <div className={styles.termsTextContent}>
                                    <p><b><span className={styles.listCount}>1.1</span> ACCESS AND USE</b></p>
                                    <p><span className={styles.listCount}>1.1.1</span> Fabacus hereby grants, and User hereby accepts, a non-exclusive, non-transferable, personal and non-sub-licensable right during the Term to access and use the Fabacus Platform and Solutions solely for User's own business purposes.</p>

                                    <p><b><span className={styles.listCount}>1.2.</span> AVAILABILITY AND SUPPORT</b></p>
                                    <p><span className={styles.listCount}>1.2.1</span> Fabacus will use commercially reasonable endeavours to provide the Fabacus Platform and Solutions in accordance with the agreed Service Level Agreement ("SLA") with the User set forth herein.</p>

                                    <p><span className={styles.listCount}>1.2.2</span> Fabacus does not actively monitor any data provided to User through the Fabacus Platform and Solutions. However, Fabacus may:</p>
                                    <p><span className={styles.listCount}>a)</span> monitor the User's use of the Fabacus Platform and Solutions to ensure and improve the quality of the Fabacus Platform and Solutions, and to verify the User's compliance with this Agreement,</p>
                                    <p><span className={styles.listCount}>b)</span> provide suggestions and/or notifications to the Users ("Alerts") in connection with any User Content or other data any User uploads to the Fabacus Platform and Solutions, but Fabacus shall be under no obligation to provide Alerts and any Alerts are provided for information purposes only and</p>
                                    <p><span className={styles.listCount}>c)</span> delete any User Content at any time if Fabacus reasonably considers the User Content is being shared in breach of this Agreement. The User shall remain solely responsibility for verifying and ensuring such data (including User Content) is correct.</p>

                                    <p><span className={styles.listCount}>1.2.3</span> User acknowledges and agrees that Fabacus is not responsible for any non-availability of Third Party Data Sites and Third Party Data Providers or the Fabacus Platform and Solutions as a result of such Third Party Sites and Third Party Data Providers.</p>

                                    <p><b><span className={styles.listCount}>1.3</span> THIRD PARTY SITES AND THIRD PARTY DATA PROVIDERS</b></p>
                                    <p><span className={styles.listCount}>1.3.1</span> User may use the Solutions to publish User Content to a Third Party Site or service. Fabacus has no control over such sites and services, and the parties acknowledge and agree that:</p>
                                    <p><span className={styles.listCount}>a)</span> the manner in which Third Party Sites use, store and disclose User Content is governed solely by the policies of such third parties; and</p>
                                    <p><span className={styles.listCount}>b)</span> the Fabacus Platform and Solutions aggregate and/or enrich User Content that is published by User and therefore Fabacus is not responsible for the accuracy, availability or reliability of any information, content or other data made available by or to the User via the Fabacus Platform or Solutions, in connection with Third Party Sites or Third Party Data provider services.</p>

                                    <p><span className={styles.listCount}>1.3.2</span> User agrees to comply with all terms and conditions of use for any Third Party Sites or services that are applicable to User's use thereof.</p>

                                    <p><span className={styles.listCount}>1.3.3</span> Fabacus is not responsible for, has no liability for, and makes no express or implied warranties with regard to: the access to or information, content or other material, products, or services that are contained on or are accessible through, or the policies regarding use and privacy in respect of, Third Party Sites; and any act or omission by Fabacus on behalf, and in accordance with the instructions (whether orally or in writing), of the User in connection with a Third Party Site.</p>

                                    <p><b><span className={styles.listCount}>1.4</span> USER OBLIGATIONS</b></p>
                                    <p><span className={styles.listCount}>1.4.1</span> User is responsible for securing all necessary prior consents for the collection, storage, and use of any User Content within the Fabacus Platform.</p>

                                    <p><b><span className={styles.listCount}>1.5</span> EXCLUSION OF WARRANTIES</b></p>
                                    <p><span className={styles.listCount}>1.5.1</span> The Solutions are provided on an "AS IS" basis and, except as expressly stated in this Agreement, all representations, warranties, conditions and other terms of any kind in respect of the Fabacus Platform and Solutions, whether express or implied, including warranties of satisfactory quality, merchantability, fitness for a particular purpose, or non-infringement, are hereby excluded to the full extent permitted by law.</p>

                                    <p><span className={styles.listCount}>1.5.2</span> Except as expressly provided for in the Client Agreement, Fabacus will not be responsible for any interruptions, delays, failures, or non-availability affecting the Solutions or the performance of the Fabacus Platform which are caused by Third Party Sites or data providers (including Fabacus Syndication Partners and/or Fabacus' hosting service provider), errors or bugs in third party software, hardware, or the internet on which Fabacus relies to provide the Solutions, any act or omission of the User or their personnel, User Content, or any changes to the Solutions made by or on behalf of the User.</p>

                                    <p><b><span className={styles.listCount}>1.6</span> LIABILITIES AND INDEMNITIES</b></p>
                                    <p><span className={styles.listCount}>1.6.1</span> The User acknowledges and agrees that:</p>
                                    <p><span className={styles.listCount}>•</span> the User assumes sole responsibility, and Fabacus has and will have no liability, in respect of any use, reliance, opinions, recommendations, forecasts, other conclusions, or any actions taken by the User or any third party based (wholly or in part, and directly or indirectly) on the User's (or any Authorised User's) use of Third Party Data or User Content or provision of (or failure to provide) any Alerts;</p>
                                    <p><span className={styles.listCount}>•</span> the User is in the best position to ascertain any likely loss it may suffer in connection with this Agreement, and that it is therefore responsible for making appropriate insurance arrangements to address the risk of any such loss;</p>
                                    <p><span className={styles.listCount}>•</span> the provisions of this Section are reasonable in these circumstances;</p>
                                    <p><span className={styles.listCount}>•</span> the User's use of the Fabacus Platform and Solutions is at the User's sole risk.</p>

                                    <p><b>SERVICE LEVEL AGREEMENT</b></p>
                                    <p><b><span className={styles.listCount}>1.</span> BACKGROUND AND DEFINITIONS</b></p>
                                    <p><span className={styles.listCount}>1.1</span> This Service Level Agreement ("SLA") is incorporated hereto and shall apply during the normal provision of the Fabacus service and where the relevant User is receiving the Fabacus service under an Agreement for use of the Fabacus Platform and Solutions (herein the "Fabacus Service" and each as defined below).</p>

                                    <p><span className={styles.listCount}>1.2</span> Unless otherwise set out below, each capitalised term in this SLA shall have the meaning set out in the Agreement, and the following capitalised terms used in this SLA shall be defined as follows:</p>

                                    <p><span className={styles.listCount}>•</span> "Agreement" means the agreement (as applicable) entered into by the Client and Fabacus;</p>
                                    <p><span className={styles.listCount}>•</span> "Business Day" means a day other than a Saturday, Sunday, or public holiday in England, when banks in London are open for business;</p>
                                    <p><span className={styles.listCount}>•</span> "Fabacus Platform and Solutions" means Fabacus's proprietary cloud-hosted, product data management platform called "Xelacore" which enables Licensors to connect and collaborate with their Licensees and Licensing Agents, Brand Manufacturers and Manufacturers to utilise the Fabacus Product Registration Module as well as Additional Service Modules to provide complete end-to-end management for all their licensed consumer products/goods;</p>
                                    <p><span className={styles.listCount}>•</span> "Licensed User" means the User (as applicable) that has entered into an Agreement with Fabacus; and</p>
                                    <p><span className={styles.listCount}>•</span> "Licensed User's Data" means, where the Licensed User has entered into an Agreement, the User Content, or other data any User uploads to the Fabacus Platform and Solutions.</p>

                                    <p><b><span className={styles.listCount}>2.</span> AVAILABILITY AND SUPPORT</b></p>
                                    <p><span className={styles.listCount}>2.1</span> Fabacus will use commercially reasonable endeavours to provide the Fabacus Service in accordance with the terms set out in this SLA.</p>

                                    <p><span className={styles.listCount}>2.2</span> Fabacus will use commercially reasonable endeavours to make the Fabacus Service available with an uptime rate of 98%, except for:</p>
                                    <p><span className={styles.listCount}>a)</span> planned maintenance, for which Fabacus will use reasonable endeavours to give 24 hours' notice to the Licensed User; and</p>
                                    <p><span className={styles.listCount}>b)</span> unscheduled maintenance during normal "Business Hours" (being the hours of 9.30am – 5pm (UK time) during a Business Day) or otherwise, or other downtime caused by its third party providers (including any Third Party Sites or hosting service provider), for which Fabacus will use reasonable endeavours to give the Licensed User advance notice.</p>

                                    <p><span className={styles.listCount}>2.3</span> Fabacus will provide reasonable e-mail, live chat, telephone, and on-site support to the Licensed User in respect of the Licensed User's use of the Fabacus Service, as follows:</p>
                                    <p><span className={styles.listCount}>a)</span> Level 1 Support: email support and live chat during Business Hours;</p>
                                    <p><span className={styles.listCount}>b)</span> Level 2 Support: telephone support during Business Hours; and</p>
                                    <p><span className={styles.listCount}>c)</span> Level 3 Support: on-site support (chargeable at an hourly rate, notified by Fabacus to the Licensed User in writing in advance).</p>

                                    <p><span className={styles.listCount}>2.4</span> The Licensed User may submit a request for Level 1 Support by e-mailing Fabacus at support@fabacus.com or raising a support ticket through Fabacus' support service. If Fabacus is unable to resolve any request for support by providing Level 1 Support, Fabacus may (in its sole determination) escalate the request to Level 2 Support, and thereafter (if agreed by the parties in writing) to Level 3 Support. For specific Concept Approval Module support, please email support@concepts.xelacore.io.</p>

                                    <p><span className={styles.listCount}>2.5</span> The Licensed User acknowledges that Fabacus may require, and the Licensed User shall procure, access to the Licensed User's Data, Third Party Data, and/or any other data sources that the Licensed User may elect to use with the Fabacus Service (whether controlled by the Licensed User or a third party), in order to provide the Fabacus Service or perform its obligations under the Agreement or this SLA.</p>

                                    <p><span className={styles.listCount}>2.6</span> The Licensed User acknowledges and agrees that Fabacus is not responsible for any non-availability of third party data source(s) or the Fabacus Services to the extent caused by a third party provider or Licensed User failure to procure any access required under SLA Section 2.5.</p>
                                </div>
                            </div>


                            <div className={styles.termsCheckbox}>
                                <label>
                                    <input onChange={checkTermsAccepted} name="terms_accepted" type="checkbox"/>
                                    <span>By creating an account, I agree to the <a target='_blank'
                                                                                    href={config.register_url}>Terms of Service</a></span>
                                </label>
                            </div>
                        </div>)}

                    <p className={classNames(styles.errorMessage, {[styles.hidden]: !errorMessage})}>{errorMessage}</p>
                    <div className={styles.submitButtonContainer}>
                        {currentWizardStep === 1 && (
                            <Button
                                disabled={isNextStepDisabled || passwordToValidate != confirmPassword || (showPass && zxcvbn(passwordToValidate).score < 3)}
                                onClick={() => {
                                    onWizardChange(2)
                                }}
                                large action type="button"
                            >
                                Continue to Terms of Service
                            </Button>)}


                        {currentWizardStep === 2 && (
                            <Button
                                disabled={!termsAccepted}
                                large action type="submit"
                            >
                                Create Account
                            </Button>)}
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export const RegisterForm = memo(RegisterFormComponent);
